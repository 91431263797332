import React from 'react';
import { ProjectLayout } from '../layouts';

export default () => <ProjectLayout
    title='Wooico'
    teaser='Place for your everyday events'
    note='Tech stack: Meteor, Django'
    year='2015'
>
    <img src='images/projects/wooico/wooico_landing.png' />
    <img src='images/projects/wooico/wooico_event.jpg' />
    <img src='images/projects/wooico/wooico_docs.jpg' />
    <img src='images/projects/wooico/wooico_settings.jpg' />
    <img src='images/projects/wooico/wooico_create.jpg' />
</ProjectLayout>;